<template>
  <div class="dropdown">
    <button
      id="navbarDropdownMenuLink"
      class="btn btn-link text-secondary pe-2 m-0 z-index-1"
      :class="[buttonPaddingClass]"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="d-block text-lg fa fa-ellipsis-v"></i>
    </button>
    <div
      class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3"
      
      aria-labelledby="navbarDropdownMenuLink"
    >
      <button
        v-for="(item, i) in items"
        :key="i"
        class="dropdown-item border-radius-md"
        @click.prevent="item.handler"
      >
        {{ item.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',

  props: {
    items: {
      type: Array,
      required: true,
      validator: (value) => value
        .every(
          (item) => [
            'handler',
            'name',
          ]
            .every(
              (key) => Object.keys(item).includes(key),
            )
        ),
    },
    buttonPaddingClass: { type: String, default: 'ps-0' }
  },
};
</script>
