<template>
  <tr>
    <td class="ps-1 align-middle" colspan="2">
      <div class="my-auto">
        <span class="text-dark d-block text-sm">
          {{ firstName }}
        </span>
      </div>
    </td>

    <td class="ps-1 align-middle">
      <span class="text-dark d-block text-sm">
        {{ insertion }}
        {{ lastName }}
      </span>
    </td>

    <td class="ps-1 align-middle">
      <span class="text-dark d-block text-sm">
        {{ emailAddress }}
      </span>
    </td>

    <td class="ps-1">
      <dropdown-menu
        :items="[
          {
            handler: () => $emit('edit'),
            name: 'Aanpassen',
          },
          {
            handler: remove,
            name: 'Verwijderen',
          },
        ]"
        class="float-start"
      />
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';
import { remove } from '@/api/providers/dashboard-users';
import { parseFullName } from '@/helpers/users';
import DropdownMenu from '@/components/UI/DropdownMenu.vue';

export default {
  name: 'DashboardUserRow',

  components: {
    DropdownMenu,
  },

  props: {
    dashboardUserId: {
      type: Number,
      required: true,
    },
    emailAddress: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    insertion: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
  },

  emits: [
    'edit',
    'remove',
  ],

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    remove() {
      const fullName = parseFullName(this.firstName, this.insertion, this.lastName);

      if (!window.confirm(`Admin ${fullName} verwijderen?`)) {
        return;
      }

      remove(this.dashboardUserId)
        .then(() => {
          this.addNotification({
            description: `${fullName} is verwijderd.`,
            icon: {
              component: 'group_remove',
            },
          });

          this.$emit('remove');
        })
        .catch((error) => error.default());
    },
  },
};
</script>
