<template>
  <div class="card mb-4">
    <div class="card-header">
      <h5>
        Admin overzicht
      </h5>
    </div>

    <div class="card-body pt-0" data-form-type="identity">
      <table class="table">
        <thead>
          <tr>
            <th class="ps-1" colspan="2">
              <p class="mb-0">
                Naam
              </p>
            </th>

            <th class="ps-1">
              <p class="mb-0">
                Achternaam
              </p>
            </th>

            <th class="ps-1">
              <p class="mb-0">
                E-mailadres
              </p>
            </th>

            <th class="ps-1">
              <p class="mb-0">
                Actie
              </p>
            </th>
          </tr>
        </thead>

        <tbody>
          <dashboard-user-row
            v-for="(admin, i) in items"
            :key="admin.id"
            :email-address="admin.emailAddress"
            :first-name="admin.firstName"
            :insertion="admin.insertion"
            :last-name="admin.lastName"
            :dashboard-user-id="admin.id"
            @edit="fillEditForm(admin)"
            @remove="removeItem(i)"
          />
        </tbody>
      </table>
    </div>
  </div>

  <form
    class="card"
    @submit.prevent="submit"
  >
    <div class="card-header">
      <h5>
        {{ formTitle }}
      </h5>
    </div>

    <div class="card-body pt-0" data-form-type="identity">
      <div class="row mt-4">
        <div class="col-3">
          <material-input
            id="first-name"
            v-model="firstName"
            :disabled="loading"
            is-required
            label="Voornaam"
            placeholder="Vul voornaam in"
            variant="static"
          />
        </div>

        <div class="col-3">
          <material-input
            id="insertion"
            v-model="insertion"
            :disabled="loading"
            label="Tussenvoegsel"
            placeholder="Vul tussenvoegsel in"
            variant="static"
          />
        </div>

        <div class="col-3">
          <material-input
            id="last-name"
            v-model="lastName"
            :disabled="loading"
            label="Achternaam"
            placeholder="Vul achternaam in"
            variant="static"
          />
        </div>

        <div class="col-3">
          <material-input
            id="email-address"
            v-model="emailAddress"
            :disabled="loading"
            is-required
            label="E-mailadres"
            placeholder="Vul e-mailadres in"
            type="email"
            variant="static"
          />
        </div>
      </div>

      <material-button
        class="float-end mt-6 mb-0"
        color="dark"
        :disabled="loading"
        variant="gradient"
        size="sm"
      >
        {{ formButtonText }}
      </material-button>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import { add, edit, getAll } from '@/api/providers/dashboard-users';
import { parseFullName } from '@/helpers/users';
import DashboardUserRow from '@/components/DashboardUsers/UserRow.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

export default {
  name: 'DashboardAdmins',

  components: {
    DashboardUserRow,
    MaterialButton,
    MaterialInput,
  },

  data: () => ({
    emailAddress: '',
    firstName: '',
    id: 0,
    insertion: '',
    items: [],
    lastName: '',
    loading: false,
  }),

  computed: {
    formButtonText() {
      return this.id
        ? 'Opslaan'
        : 'Toevoegen';
    },

    formTitle() {
      return this.id
        ? 'Admin aanpassen'
        : 'Admin toevoegen';
    },
  },

  mounted() {
    this.loadAdmins();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    add() {
      return add(
        this.firstName,
        this.insertion,
        this.lastName,
        this.emailAddress,
      );
    },

    edit() {
      return edit(
        this.id,
        this.firstName,
        this.insertion,
        this.lastName,
        this.emailAddress,
      );
    },

    fillEditForm(item) {
      this.id = item.id;
      this.firstName = item.firstName;
      this.insertion = item.insertion;
      this.lastName = item.lastName;
      this.emailAddress = item.emailAddress;
    },

    handleSaveSuccess(data) {
      const index = this.items.findIndex((item) => item.id === data.id);

      if (
        !(
          !this.id
          && index > -1
        )
      ) {
        this.updateItems(data, index);
        this.showSaveSuccessNotification();
      }

      this.resetFormValues();
    },

    loadAdmins() {
      getAll()
        .then((data) => {
          this.items = data || [];
        })
        .catch((error) => error.default());
    },

    removeItem(index) {
      this.items.splice(index, 1);
    },

    resetFormValues() {
      this.id = 0;
      this.firstName = '';
      this.insertion = '';
      this.lastName = '';
      this.emailAddress = '';
    },

    showSaveSuccessNotification() {
      const fullName = parseFullName(
        this.firstName,
        this.insertion,
        this.lastName,
      );

      this.addNotification({
        description: `${fullName} is opgeslagen.`,
        icon: {
          component: 'group_add',
        },
      });
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      const action = this.id
        ? this.edit
        : this.add;

      action()
        .then(this.handleSaveSuccess)
        .catch((error) => error.default())
        .then(() => {
          this.loading = false;
        });
    },

    updateItems(item, index = undefined) {
      if (index > -1) {
        this.items[index] = item;
      } else {
        this.items.push(item);
      }
    },
  },
};
</script>
